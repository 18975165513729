import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <img src="/logo.png" alt="logo" className="logo"></img>
      <header className="header">
        <h1>Sizlere daha iyi hizmet vermek için bakımdayız.</h1>
      </header>
      <main className="content">
        <h2>URLANATURA</h2>
        <p>info@urlanatura.com</p>
        <p>+90 534 659 88 98</p>
      </main>
    </div>
  );
}

export default App;
